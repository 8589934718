import React, { useState } from "react";
import {
  Center,
  Paper,
  TextInput,
  Button,
  Title,
  Container,
  Text,
  Flex,
  ThemeIcon,
  Alert,
} from "@mantine/core";
import { IconMailShare, IconAlertCircle } from "@tabler/icons-react";
import { useResendVerification } from "../hooks/useRegister";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";

const Resend = () => {
  const history = useHistory();
  const redirectedErrors = history.location.state?.message ?? {};
  const [resendEmail, setResendEmail] = useState("");
  const {
    mutate: resendVerification,
    isError,
    error,
    isSuccess,
  } = useResendVerification();

  const handleResend = () => {
    resendVerification(resendEmail);
  };

  if (isSuccess) {
    return <ResendSuccess data={resendEmail} />;
  }

  return (
    <Center h="100vh">
      <Paper shadow="xs" px="md" py="xl" maw={"400px"} miw="400px">
        <Title order={3} mb={8}>
          Resend Verification Email
        </Title>
        <Text mb={24}>Please enter the email that you registered with.</Text>

        {/* Possible Error Messages  */}
        {isError && error.response.data.message === "verified" && (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Email Verified"
            color="red"
          >
            It seems like your email is verified. Please proceed to{" "}
            <Link to={"login"}>login</Link>
          </Alert>
        )}
        {isError && error.response.status === 404 && (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Email not found"
            color="red"
          >
            This email does not exist. Please enter the email that you
            registered with. If you have not register, click{" "}
            <Link to={"register"}>here</Link> to register
          </Alert>
        )}
        {Object.values(redirectedErrors).length > 0 && (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Email not yet verified"
            color="red"
          >
            {Object.values(redirectedErrors)}
          </Alert>
        )}
        <TextInput
          placeholder="example@gmail.com"
          label="Email"
          value={resendEmail}
          onChange={event => setResendEmail(event.currentTarget.value)}
        />
        <Button mt={16} onClick={handleResend}>
          Resend Email
        </Button>
      </Paper>
    </Center>
  );
};

export default Resend;

const ResendSuccess = ({ data }) => {
  return (
    <Center h="100vh">
      <Paper shadow="xs" px="md" py="xl" maw={"400px"} miw="400px">
        <Flex
          gap="md"
          justify="center"
          align="center"
          direction="column"
          wrap="wrap"
        >
          <Container>
            <ThemeIcon size="xl">
              <IconMailShare />
            </ThemeIcon>
          </Container>
          <Title order={3}>Email Sent</Title>
          <Text align="center">
            {`Check your email for the verification link sent to ${data}`}
          </Text>
        </Flex>
      </Paper>
    </Center>
  );
};
