import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  Header,
  Image,
  Text,
  Menu,
  Footer,
} from "@mantine/core";
import React from "react";
import { permissionTypes } from "../constants/documentTypes";
import "./layout.css";
import { IconBook, IconLogout, IconUser } from "@tabler/icons-react";
import { useHistory } from "react-router-dom";
import setAuthToken from "../utils/setAuthToken";
import { useAppStore } from "../lib/zustandStore";
import { useGetMyInfo } from "../hooks/useGetMyInfo";

const HeaderComponent = () => {
  const { data: myInfo } = useGetMyInfo();
  const history = useHistory();

  const changeIsEdit = useAppStore(state => state.changeIsEdit);
  const changeApp = useAppStore(state => state.changeAppId);

  const routes = [
    {
      label: "Programs",
      icon: <IconBook size={16} />,
      path: "/programs",
      type: "link",
      onClick: () => window.open("https://tbcollege.com"),
    },
  ];

  const logoutUser = () => {
    localStorage.removeItem("jwtToken");
    setAuthToken(false);
    history.push("/");
    window.location.reload();
  };

  return (
    <Header>
      <Container size="xl">
        <Flex py="sm" justify="space-between" align="center">
          <Flex align="center" gap="md">
            <Image
              src="/assets/logo.png"
              height={60}
              width={160}
              fit="contain"
              mr={36}
              className="clickable"
              onClick={() => history.push("/")}
            />
            {routes.map(route => (
              <Flex
                align="center"
                gap="xs"
                className="clickable"
                key={route.label}
                onClick={route.onClick}
              >
                {route.icon}
                <Text>{route.label}</Text>
              </Flex>
            ))}
          </Flex>
          <Box display="flex">
            <Button
              size="xs"
              variant="outline"
              my="auto"
              mr="xl"
              onClick={() => {
                changeIsEdit(false);
                changeApp(null);
                history.push("/form");
              }}
            >
              New Application
            </Button>
            <Menu position="bottom-end" shadow="md">
              <Menu.Target>
                <Flex align="center" justify="center" className="avatar-card">
                  <Avatar color="cyan" mr="md">
                    {myInfo?.name.slice(0, 2)}
                  </Avatar>
                  <Box>
                    <Text>{myInfo?.name}</Text>
                    <Text color="gray">
                      {permissionTypes[myInfo?.permission.toString()]}
                    </Text>
                  </Box>
                </Flex>
              </Menu.Target>
              <Menu.Dropdown width={200}>
                {/*<Menu.Item icon={<IconUser size={14} />}>Profile</Menu.Item> */}
                <Menu.Item icon={<IconLogout size={14} />} onClick={logoutUser}>
                  Logout
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Box>
        </Flex>
      </Container>
    </Header>
  );
};

const FooterComponent = () => {
  return (
    <Footer height="8vh" p="md" id="footer" zIndex={5}>
      <Container size="xl">
        <Flex justify="space-between" align="center">
          <Text>
            Copyright © {new Date().getFullYear()} All Rights Reserved
          </Text>
          <Flex align="center" gap="xs" />
        </Flex>
      </Container>
    </Footer>
  );
};

const Layout = ({ children }) => {
  return (
    <div id="page-container">
      <HeaderComponent />
      <div id="content-wrap">
        <Box my={16}>{children}</Box>
      </div>
      <FooterComponent />
    </div>
  );
};

export default Layout;
