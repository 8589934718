import React, { useEffect } from "react";
import {
  Anchor,
  Box,
  Button,
  Center,
  Image,
  Paper,
  PasswordInput,
  Stack,
  TextInput,
  Alert,
  Text,
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { userLogin2 } from "../actions/authActions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom";
import queryClient from "../lib/queryClient";
import { useLogin } from "../hooks/useGetMyInfo";

const initialValues = {
  email: "",
  password: "",
};

const Login = props => {
  const history = useHistory();
  const { mutate: login, error } = useLogin();

  const form = useForm({
    initialValues,
  });

  const handleSubmit = async values => {
    login(
      { email: values.email, password: values.password },
      {
        onSuccess: async data => {
          await props.userLogin2(data.token); // add token to redux store
          queryClient.invalidateQueries(["myInfo"]);
          return history.push("/applications");
        },
        onError: async error => {
          form.setErrors(error.response?.data);
          return history.push("/resend", { message: error.response?.data });
        },
      }
    );
  };

  useEffect(() => {
    if (props.auth.isAuthenticated) {
      return history.push("/applications");
    }
  }, []);

  return (
    <Center h="100vh">
      <Paper shadow="xs" px="md" py="xl" maw={"500px"} miw="500px">
        <Center>
          <Stack>
            {props?.location?.state?.message && (
              <Alert
                variant="light"
                color="blue"
                title="Email Verification"
                icon={<IconAlertCircle size="1rem" />}
              >
                {props.location.state.message}
              </Alert>
            )}
            <Image src="/assets/logo.png" className="mainLogo" maw="300px" />
            <form onSubmit={form.onSubmit(values => handleSubmit(values))}>
              <TextInput
                label="Email Address"
                {...form.getInputProps("email")}
                error={error && error.response.data.email}
              />
              <PasswordInput
                label="Password"
                {...form.getInputProps("password")}
              />
              <Box>
                <Anchor href="/forgot-password" size="xs">
                  Forgot Password
                </Anchor>
              </Box>
              <Button mt={"md"} type="submit">
                Login
              </Button>
            </form>
            <Text>
              No account? <Link to={"register"}>Create account</Link>
            </Text>
          </Stack>
        </Center>
      </Paper>
    </Center>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { userLogin2 }
)(Login);
