/**
 * Vulnerable sector disclaimer is only required for PSW, and ECA
 * Medical disclaimer is only required for PSW, ECA
 */

/**
 * @readonly
 * @type {Array<{value: string, label: string, staffOnly: boolean, onlyFor?: string[]}>}
 */
export const documentTypes = [
  { value: "8", label: "Wire Transfer Receipt", staffOnly: true },
  { staffOnly: false, value: "2", label: "ID Card" },
  { staffOnly: false, value: "3", label: "Academic Documents" },
  { staffOnly: false, value: "4", label: "IELTS/PTE/OTHERS" },
  { staffOnly: false, value: "10", label: "High School Marksheets" },
  { staffOnly: false, value: "11", label: "High School Certificate" },
  { staffOnly: false, value: "15", label: "Bachelors Marksheets" },
  { staffOnly: false, value: "16", label: "Bachelors Certificate" },
  { staffOnly: false, value: "17", label: "Post-Graduate Marksheets" },
  { staffOnly: false, value: "18", label: "Post-Graduate Certificate" },
  { staffOnly: false, value: "19", label: "Employment Documents" },
  { staffOnly: false, value: "20", label: "Valid Government Issued Photo ID" },
  { staffOnly: false, value: "21", label: "Proof of English Proficiency" },
  {
    staffOnly: false,
    value: "22",
    label: "Medical Insurance (Int.) request (BA 34)",
  },
  {
    staffOnly: false,
    value: "23",
    label: "Vulnerable Sector Disclaimer",
    onlyFor: ["PSW", "ECA"],
  },
  {
    staffOnly: false,
    value: "24",
    label: "Medical Disclaimer",
    onlyFor: ["PSW", "ECA"],
  },
  { staffOnly: false, value: "5", label: "Others Documents" },
];
