// @flow
import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "tabler-react/dist/Tabler.css";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { LastLocationProvider } from "react-router-last-location";
import PrivateRoute from "./components/common/PrivateRoute.js";

// Get the pages
import Applications2 from "./pages/Applications/Applications2.jsx";
import Programs2 from "./pages/Programs/index.jsx";
import ApplicationRoute from "./pages/formSubmission/ApplicationRoute.react";
import Documents2 from "./pages/Documents/View";
import Agents from "./pages/Agents";
import Availability from "./pages/Availability";
import Terms from "./pages/Terms.react";
import Register from "./pages/Register.jsx";
import { ReactQueryDevtools } from "react-query/devtools";
import ApplicationForm from "./pages/ApplicationForm/ApplicationForm";
import Login from "./pages/Login.jsx";
import { Notifications } from "@mantine/notifications";
import ForgotPassword from "./pages/ForgotPassword";
import Verify from "./pages/Verify.jsx";
import Resend from "./pages/Resend.jsx";
import Payment from "./pages/Payment/View.jsx";

import Layout from "./components/Layout.jsx";
import Block from "./pages/Block.jsx";

// Check for the token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get the user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set the user to be not isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check if the token is expired
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "/login";
  }
}

function App() {
  return (
    <>
      <Notifications zIndex={10} />
      <Provider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <LastLocationProvider>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/verify" component={Verify} />
              <Route path="/resend" component={Resend} />
              <Layout>
                <PrivateRoute exact path="/" component={Applications2} />
                <PrivateRoute
                  exact
                  path="/applications"
                  component={Applications2}
                />
                <PrivateRoute
                  exact
                  path="/applications2"
                  component={Applications2}
                />
                <PrivateRoute exact path="/programs" component={Programs2} />
                <PrivateRoute exact path="/documents" component={Documents2} />
                <PrivateRoute
                  path="/application"
                  component={ApplicationRoute}
                />
                <PrivateRoute path="/form" component={ApplicationForm} />
                <PrivateRoute path="/all" component={Applications2} />
                <PrivateRoute path="/agents" component={Agents} />
                <PrivateRoute path="/availability" component={Availability} />
                <PrivateRoute path="/terms" component={Terms} />
                <PrivateRoute path="/payment" component={Payment} />
              </Layout>
            </Switch>
          </LastLocationProvider>
        </BrowserRouter>
      </Provider>
      {process.env.REACT_APP_RQ_ENV === "development" ? (
        <ReactQueryDevtools />
      ) : null}
    </>
  );
}

export default App;
