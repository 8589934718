import { Center, Image, Stack, Text } from "@mantine/core";
import React from "react";

const Block = () => {
  return (
    <div>
      <Center>
        <Stack maw={"500px"} miw="500px" my="auto" mih="100vh">
          <Center>
            <Image src="/assets/logo.png" className="mainLogo" maw="300px" />
          </Center>
          <Text size="xl" align="center" mt="md">
            Our website is launching soon! Come back on August 14, 2024 to start
            registering.
          </Text>
        </Stack>
      </Center>
    </div>
  );
};

export default Block;
