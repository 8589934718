import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../utils/loadStripe";
import CheckoutForm from "./CheckoutForm";
import Completion from "./Completion";
import { Switch, useLocation, useHistory } from "react-router-dom";

import PrivateRoute from "../../components/common/PrivateRoute";
import useSearchParams from "../../hooks/useSearchParams";
import { Divider, Flex, Grid, Paper, Stack, Text, Title } from "@mantine/core";

const View = ({ data, form }) => {
  const location = useLocation();
  const history = useHistory();
  const [clientSecret, setClientSecret] = useState(data);
  const { getParams } = useSearchParams();
  const searchParams = getParams();
  const paymentIntentClientSecret = searchParams.get(
    "payment_intent_client_secret"
  );

  const appearance = { theme: "stripe" };
  const options = { clientSecret, appearance };

  // auto redirect to complete page after checkout
  useEffect(() => {
    if (paymentIntentClientSecret) {
      setClientSecret(paymentIntentClientSecret);
      history.push({
        pathname: "/payment/complete",
        search: location.search, // this is from stripe api redirect after payment complete
      });
    }
  }, []);
  const p = form?.values?.personal?.ProgramsAvailable?.Program;
  return (
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          {!paymentIntentClientSecret && (
            <Stack>
              <Paper shadow="xs" px="md" py="xl">
                <Title size="h3" mb={24}>
                  Checkout Form
                </Title>
                <Divider my="lg" />
                <Grid columns={24} gap={20}>
                  <Grid.Col
                    span={14}
                    sx={{ borderRight: "1px solid", borderColor: "#ced4da" }}
                  >
                    <CheckoutForm />
                  </Grid.Col>
                  <Grid.Col span={10}>
                    <Text>
                      {p?.code} - {p?.name} - ${p?.price / 100}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Paper>
            </Stack>
          )}
          <Switch>
            <PrivateRoute path="/payment/complete" component={Completion} />
          </Switch>
        </Elements>
      )}
    </>
  );
};

export default View;
