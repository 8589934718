import React, { useEffect, useState } from "react";
import { Box, Button, Center, Container, Stack, Stepper } from "@mantine/core";
import Step1 from "./Step1";
import StepSchedule from "./StepSchedule";
import { useForm } from "@mantine/form";
import { useAppStore } from "../../lib/zustandStore";
import { useHistory } from "react-router-dom";
import {
  useGetOneApplication,
  usePostStep1,
  usePostStep2,
  usePostStepSchedule,
} from "./api";
import { notifications } from "@mantine/notifications";
import { APP_STATUS } from "../../utils/applicationStatus";
import View from "../Payment/View";
import { useCreatePaymentIntent } from "../Payment/api";

const initialValues = {
  personal: {
    id: "",
    MailingAddress: "",
    MailingAddress2: "",
    city: "",
    postalCode: "",
    telephone: "",
    status: "",
    notifications: "",
    country: "",
    province: "",
    name_emergency: "",
    phone_emergency: "",
    email_emergency: "",
    relation_emergency: "",
    meta: "",
    program: "",
    applicationLogs: "",
    applicationDocuments: "",
    group: "",
    ProgramsAvailable: {
      Programs: {
        institution: "",
        code: "",
      },
    },
    program_second: "",
    User: "",
    frenchDisclaimer: false,
  },
};

const ApplicationForm = () => {
  let history = useHistory();

  const changeApp = useAppStore(state => state.changeAppId);
  const appId = useAppStore(state => state.appId);
  const changeIsEdit = useAppStore(state => state.changeIsEdit);
  const isEdit = useAppStore(state => state.isEdit);

  const { data, isLoading } = useGetOneApplication(appId);

  const form = useForm({
    initialValues,
  });

  const handleCheckout = e => {
    e.preventDefault();
    createPaymentIntend(
      { data: { product: [{ id: "shirt" }] } },
      {
        onSuccess: data =>
          history.push({
            pathname: "/payment",
            state: { clientSecret: data.clientSecret },
          }),
      }
    ); // TODO: add in selected product
  };

  useEffect(
    () => {
      if (data) {
        // group needs to be a string because of radio buttons
        // default to 1 if no group is selected
        form.setValues(values => ({
          ...values,
          personal: {
            ...data,
            group: data.group ? data.group.toString() : "1",
          },
        }));
      }
    },
    [data]
  );

  useEffect(
    () => {
      if (isEdit) {
        changeApp(appId);
      }
    },
    [appId]
  );

  const { mutateAsync: mutateStep1 } = usePostStep1();
  const { mutateAsync: mutateStepSchedule } = usePostStepSchedule();
  //const { mutateAsync: mutateStep2 } = usePostStep2();

  const {
    mutate: createPaymentIntend,
    data: clientSecret,
    isSuccess,
  } = useCreatePaymentIntent();

  // stepper state
  const [active, setActive] = useState(0);

  const handleErrorResponse = (obj, str) => {
    const properError = Object.keys(obj).reduce(
      (v, k) => ({
        ...v,
        [str + "." + k]: obj[k],
      }),
      null
    );

    form.setErrors(properError);
  };

  const nextStep = async () => {
    // personal step
    if (active === 0) {
      // api call
      try {
        const returnData = await mutateStep1({
          ...form.values.personal,
          editMode: isEdit,
        });
        if (!appId) {
          changeApp(returnData.id);
        }
      } catch (err) {
        let obj = err?.response?.data;
        handleErrorResponse(obj, "personal");
        notifications.show({
          color: "red",
          title: err?.response?.data.message,
          style: { zIndex: 5 },
        });
        return;
      }
    }

    // schedule step
    if (active === 1) {
      try {
        await mutateStepSchedule({
          group: form.values.personal.group,
          application: appId,
        });
        setActive(2);
        createPaymentIntend({ data: { product: [{ id: "shirt" }], appId } });
        // history.push(`/application/view/${appId}`);
      } catch (error) {
        notifications.show(
          "Something went wrong picking a schedule. Please try again."
        );
      }
    }

    // payment step
    if (active === 3) {
      console.log("payment step");
    }

    window.scrollTo(0, 0);
    setActive(current => (current < 2 ? current + 1 : current));
  };

  const prevStep = () => {
    window.scrollTo(0, 0);
    setActive(current => (current > 0 ? current - 1 : current));
    if (isEdit !== true) {
      changeIsEdit(true);
    }
  };

  if (isLoading) {
    return <>Loading</>;
  }

  return (
    <Container my="xl">
      <Center>
        <Stack>
          <Stepper active={active} breakpoint="sm">
            <Stepper.Step label="Step 1" description="Personal Information">
              <Step1 step={0} form={form} />
            </Stepper.Step>
            {/*<Stepper.Step label="Step 2" description="Document Upload">
              <Step4 step={1} form={form} />
            </Stepper.Step> */}
            <Stepper.Step label="Step 2" description="Schedule">
              <StepSchedule step={1} form={form} />
            </Stepper.Step>
            <Stepper.Step label="Step 3" description="Payment">
              {isSuccess && (
                <View step={2} data={clientSecret.clientSecret} form={form} />
              )}
            </Stepper.Step>
          </Stepper>
          <Box
            sx={theme => ({
              marginLeft: "auto",
              marginRight: 0,
              marginBottom: theme.spacing.xl,
              display: "flex",
            })}
          >
            {/*
            {active !== 0 && (
              <Button
                fullWidth={false}
                variant="default"
                onClick={prevStep}
                mr="md"
              >
                Previous
              </Button>
            )} */}
            {active !== 2 && (
              <Button fullWidth={false} onClick={nextStep}>
                Next
              </Button>
            )}
          </Box>
        </Stack>
      </Center>
    </Container>
  );
};

export default ApplicationForm;
