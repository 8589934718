import React from "react";
import { Box, Center, Pagination, Text } from "@mantine/core";
import ApplicationsTable from "./ApplicationsTable";
import { useGetApplications } from "./api";
import useSearchParams from "../../hooks/useSearchParams";

export const tableHeaders = [
  "Assigned ID",
  "Name",
  "Status",
  "Term",
  "Program/Micro-credential",
  "Created",
  "",
];
const Applications2 = () => {
  const { getParams, updateParam } = useSearchParams();
  const searchParams = getParams();
  const page = searchParams.get("page") || 1;

  const handlePageClick = inputPage => {
    updateParam("page", inputPage.toString());
  };

  const handleSearch = (key, value) => {
    updateParam(key, value);
  };

  const { isLoading, data: appData } = useGetApplications(
    searchParams.toString()
  );

  return (
    <Box mx={16}>
      <>
        <ApplicationsTable appData={appData?.[0]} isLoading={isLoading} />
        {appData?.[0].length === 0 && (
          <Center>
            <Box textAlign="center" mt={16}>
              <Text size="lg" color="dimmed">
                No applications found. Get started by creating a new
                application.
              </Text>
            </Box>
          </Center>
        )}
        <Pagination
          total={appData?.[1] / 10}
          siblings={2}
          value={parseInt(page)}
          onChange={handlePageClick}
          my={8}
        />
      </>
    </Box>
  );
};

export default Applications2;
