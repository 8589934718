import { useQuery, useMutation } from "react-query";
import axios from "axios";
import config from "../config/config";

/**
 * @return {Promise<import("../types").UserInfo>}
 */
const fetchMyInfo = async () => {
  const res = await axios.get(`${config.server}/users/current`);
  return res.data;
};

export const useGetMyInfo = () =>
  useQuery(["myInfo"], () => fetchMyInfo(), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    enabled: !!localStorage?.jwtToken, // prevent from fetching user info if there is no jwt token in session
  });

// login hook
export const useLogin = () => {
  return useMutation({
    mutationFn: async data => {
      const res = await axios.post(
        `${config.server}${config.api.userLogin}`,
        data
      );
      return res.data;
    },
  });
};
