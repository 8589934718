import { useQuery } from "react-query";
import axios from "axios";
import config from "../../config/config";

export const useGetApplications = filter =>
  useQuery(
    ["applications", filter],
    async () => {
      const res = await axios.get(
        `${config.server}/applications/v2/all?${filter}`
      );
      return res.data;
    },
    {
      keepPreviousData: true,
      refetchOnMount: false,
    }
  );

export const useGetFilterOptions = () =>
  useQuery(["filterOptions"], async () => {
    const res = await axios.get(`${config.server}/applications/filterOptions`);
    return res.data;
  });

export const useGetAllTerms = () =>
  useQuery(["terms"], async () => {
    const res = await axios.get(`${config.server}${config.api.termsGetActive}`);
    return res.data;
  });
