import {
  Divider,
  Grid,
  Paper,
  Radio,
  Stack,
  Table,
  Text,
  Title,
} from "@mantine/core";
import { useHistory } from "react-router-dom";
import React from "react";
import { useGetSchedules } from "./api";
import { twentyFourToTwelve } from "../../utils/timeStringConversion";

const StepSchedule = ({ form }) => {
  let history = useHistory();

  const { data, isLoading } = useGetSchedules(form?.values?.personal.program);

  if (!form.values.personal.program) {
    history.push("/");
  }

  if (isLoading) return <>Loading</>;
  const ths = (
    <tr>
      <th />
      <th>Group</th>
      <th>Day</th>
      <th>Start Time</th>
      <th>End Time</th>
    </tr>
  );

  return (
    <>
      <Stack>
        <Paper shadow="xs" px="md" py="xl">
          <Title size="h3">Select Your Schedule</Title>
          <Divider my="lg" />

          <Grid>
            <Grid.Col span={12}>
              <Text>
                Starting the week of:{" "}
                <strong>{data.json.programInfo.start_date}</strong>
              </Text>
              <Text>
                Duration: {data.json.programInfo.Program.durationWks} weeks
              </Text>
            </Grid.Col>
            <Grid.Col span={12}>
              <Radio.Group
                mt="md"
                defaultValue="1"
                {...form.getInputProps("personal.group")}
              >
                <Table withColumnBorders>
                  <thead>{ths}</thead>
                  <tbody>
                    {Object.entries(data.json.data).map(row => (
                      <tr key={row[0]}>
                        <td>
                          <Radio value={String(row[0])} />
                        </td>
                        <td>
                          <Text>{row[0]}</Text>
                        </td>
                        <td>
                          {row[1].map(subRow => (
                            <Text key={subRow.day}>{subRow.day}</Text>
                          ))}
                        </td>
                        <td>
                          {row[1].map(subRow => (
                            <Text key={subRow.day}>
                              {twentyFourToTwelve(subRow.start)}
                            </Text>
                          ))}
                        </td>
                        <td>
                          {row[1].map(subRow => (
                            <Text key={subRow.day}>
                              {twentyFourToTwelve(subRow.end)}
                            </Text>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Radio.Group>
            </Grid.Col>
          </Grid>
        </Paper>
      </Stack>
    </>
  );
};

export default StepSchedule;
