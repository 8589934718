import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Button, Card, Container, Header } from "tabler-react";
import { useRegisterApplicant } from "../hooks/useRegister";
import { userLogin } from "../actions/authActions";
import { countryList } from "../utils/countriesList";
import { TextInput, NativeSelect, PasswordInput, Radio } from "@mantine/core";

const Register = props => {
  const { mutate: registerApplicant, error } = useRegisterApplicant();
  const registerUser = values => {
    if (values.password === values.password2) {
      registerApplicant(values, {
        onSuccess: data => {
          props.history.push("/login", { message: data.message });
        },
      });
    }
  };

  const handleValidation = values => {
    const errors = {};

    if (!values.email) {
      errors.email = "Email cannot be empty";
    }
    if (!values.password) errors.password = "Required";

    if (values.password.length < 6)
      errors.password = "Must be at least 6 characters long";

    if (
      values.password !== "" &&
      values.password2 !== "" &&
      values.password !== values.password2
    ) {
      errors.password = "Passwords do not match";
    }
    return errors;
  };

  useEffect(() => {
    if (props.auth?.isAuthenticated) {
      return props.history.push("/applications");
    }
  }, []);

  return (
    <Container className="defaultMargin">
      <div style={{ width: "400px", margin: "auto" }}>
        <Card>
          <Card.Body>
            <div>
              <img src="/assets/logo.png" className="mainLogo" />
            </div>
            <Header.H3>Register an account</Header.H3>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                country: "",
                gender: "male",
                email: "",
                password: "",
                password2: "",
              }}
              validate={values => handleValidation(values)}
              onSubmit={async (values, { setSubmitting }) => {
                registerUser(values);
                setSubmitting(false);
              }}
            >
              {({
                errors,
                values,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextInput
                    label="First Name"
                    name="firstName"
                    type="text"
                    value={values.firstName}
                    onChange={handleChange}
                    error={error && error.response.data.data.firstName}
                    withAsterisk
                    mb={8}
                  />
                  <TextInput
                    label="Last Name"
                    name="lastName"
                    type="text"
                    value={values.lastName}
                    onChange={handleChange}
                    error={error && error.response.data.data.lastName}
                    withAsterisk
                    mb={8}
                  />
                  <NativeSelect
                    label="Country"
                    name="country"
                    placeholder="Country"
                    searchable
                    data={countryList}
                    value={values.country}
                    onChange={handleChange}
                    error={error && error.response.data.data.country}
                    withAsterisk
                    mb={8}
                  />
                  <Radio.Group
                    value={values.gender}
                    onClick={handleChange}
                    name="gender"
                    label="Gender"
                    withAsterisk
                    mb={8}
                  >
                    <Radio value="male" label="Male" />
                    <Radio value="female" label="Female" />
                    <Radio value="other" label="Other" />
                  </Radio.Group>
                  <TextInput
                    label="Email"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    withAsterisk
                    error={
                      errors.email || (error && error.response.data.data.email)
                    }
                    mb={8}
                  />
                  <PasswordInput
                    label="Password"
                    name="password"
                    withAsterisk
                    value={values.password}
                    onChange={handleChange}
                    error={errors.password}
                    mb={8}
                  />
                  <PasswordInput
                    label="Confirm Password"
                    name="password2"
                    withAsterisk
                    value={values.password2}
                    onChange={handleChange}
                    mb={8}
                  />
                  <Button type="submit" disabled={isSubmitting}>
                    Register
                  </Button>
                </form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  { userLogin }
)(Register);
