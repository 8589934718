import axios from "axios";
import { useMutation } from "react-query";
import config from "../../config/config";

export const useCreatePaymentIntent = () => {
  return useMutation({
    mutationFn: async data => {
      const res = await axios.post(
        `${config.server}/payments/create-payment-intent`,
        data
      );
      return res.data;
    },
  });
};
