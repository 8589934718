import { useMutation, useQuery } from "react-query";
import axios from "axios";
import config from "../config/config";

/**
 *
 * @param {Object} payload
 * @param {string} payload.username
 * @param {string} payload.companyName
 * @param {string} payload.password
 * @returns {Promise<string> | Error}
 */
const registerAgent = async payload => {
  const res = await axios.post(
    `${config.server}${config.api.agentRegister}`,
    payload
  );

  return res.data;
};

export const useRegisterAgent = () => {
  return useMutation(registerAgent);
};

export const useRegisterApplicant = () => {
  return useMutation({
    mutationFn: async data => {
      const res = await axios.post(
        `${config.server}${config.api.applicantRegister}`,
        data
      );
      return res.data;
    },
  });
};

export const useVerifyApplicant = token =>
  useQuery(
    ["verify_applicant", { token }],
    async () => {
      const res = await axios.get(
        `${config.server}${config.api.applicantVerify}?token=${token}`
      );

      return res.data;
    },
    { enabled: !!token }
  );

export const useResendVerification = () => {
  return useMutation({
    mutationFn: async email => {
      const res = await axios.post(
        `${config.server}${config.api.applicantResend}`,
        { email }
      );

      return res.data;
    },
  });
};
