import React, { useState } from "react";
import SiteWrapperReact from "../../SiteWrapper.react";
import {
  ActionIcon,
  Anchor,
  Button,
  Container,
  FileInput,
  Flex,
  Group,
  Modal,
  Paper,
  Table,
  Title,
} from "@mantine/core";
import { renderFor } from "../../utils/renderComp";
import {
  useFetchAllDocuments,
  useUploadFile,
  useDeletePublicDocument,
} from "./api";
import queryClient from "../../lib/queryClient";
import { IconCheck, IconDownload, IconTrash, IconX } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { openInNewTab } from "../../utils/downloadFile2";

const View = () => {
  const { data, isLoading } = useFetchAllDocuments();
  const myInfo = queryClient.getQueryData("myInfo");
  const [file, setFile] = useState(null);

  const [opened, { open, close }] = useDisclosure(false);

  const uploadFile = useUploadFile();
  const { mutateAsync: deleteFile } = useDeletePublicDocument();

  const handleUploadFile = async () => {
    // if null, don't try upload
    if (file === null) {
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      await uploadFile.mutateAsync(formData);

      notifications.show({
        title: "File uploaded!",
        color: "teal",
        icon: <IconCheck />,
      });
      close();
      setFile(null);
    } catch (err) {
      console.log(err);
      notifications.show({
        title: "Something went wrong upload your file!",
        color: "red",
        icon: <IconX />,
      });
    }
    await queryClient.invalidateQueries(["public-documents"]);
  };

  const handleDeleteFile = async code => {
    try {
      await deleteFile(code);

      notifications.show({
        title: "File deleted!",
        color: "teal",
        icon: <IconCheck />,
      });
      await queryClient.invalidateQueries(["public-documents"]);
    } catch (err) {
      console.log(err);
      notifications.show({
        title: "Something went wrong deleting your file!",
        color: "red",
        icon: <IconX />,
      });
    }
  };

  const actionButtons = document => {
    if (myInfo?.permission === 1)
      return (
        <ActionIcon
          color="blue"
          size="lg"
          variant="outline"
          mr={8}
          onClick={evt => openInNewTab(evt, document)}
        >
          <IconDownload size="1.2rem" />
        </ActionIcon>
      );

    if (myInfo?.permission === 0 || myInfo?.permission === 2)
      return (
        <Flex>
          <ActionIcon
            color="blue"
            size="lg"
            variant="outline"
            mr={8}
            onClick={evt => openInNewTab(evt, document)}
          >
            <IconDownload size="1.2rem" />
          </ActionIcon>
          <ActionIcon
            color="red"
            size="lg"
            variant="outline"
            mr={8}
            onClick={() => handleDeleteFile(document.code)}
          >
            <IconTrash size="1.2rem" />
          </ActionIcon>
        </Flex>
      );
  };

  const tableHeaders = () => {
    return (
      <tr>
        <th>File name</th>
        <th>Posted on</th>
        <th>Options</th>
      </tr>
    );
  };

  const tableRows = () => {
    return data.data.map(document => (
      <tr key={document.id}>
        <td>{document.name}</td>
        <td>{document.createdAt.substring(0, 10)}</td>
        <td>{actionButtons(document)}</td>
      </tr>
    ));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (data.length === 0) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      <Modal opened={opened} title="New Document" onClose={close}>
        <FileInput
          label="Submit your public document below"
          accept="image/*, application/pdf"
          onChange={file => setFile(file)}
          value={file}
          mt="lg"
        />
        <Group position="right" mt="md">
          <Button onClick={close} color="red">
            Cancel
          </Button>
          <Button onClick={handleUploadFile}>Upload</Button>
        </Group>
      </Modal>
      <Container size="lg" my={32} py={16} px={0}>
        <Paper shadow="xs" p="md">
          <Flex justify="space-between">
            <Title size="h3" ml={16}>
              Documents
            </Title>
            {renderFor([0], true, myInfo?.permission) && (
              <Button onClick={open}>Add new document</Button>
            )}
          </Flex>
          <Table mt="xl">
            <thead>{tableHeaders()}</thead>
            <tbody>
              {data.data.length > 0 ? tableRows() : <tr>No documents</tr>}
            </tbody>
          </Table>
        </Paper>
      </Container>
    </>
  );
};

export default View;
