// @flow

// Get the base for this page
import * as React from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import { Site, Nav, Grid, List, Button } from "tabler-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "babel-polyfill";

// Get the utils functions
import isEmpty from "./utils/is-empty";

// Get all the actiosn
import { logoutUser, registerUser, userProfile } from "./actions/authActions";
import { setCurrentApplication } from "./actions/applicationActions";
import {
  announcementSet,
  announcementGet,
} from "./actions/announcementActions";
import { settingSet, settingGet } from "./actions/settingsActins";

// Get the modals
import AgentAdd from "./components/modals/AgentAdd";
import Announcements from "./components/modals/Announcements";
import PortalSettings from "./components/modals/Settings";
import DataExchange from "./components/modals/DataExchange";

// Get the third party libraries
import { avatarGenerator } from "./utils/colorGenerator";
import Swal from "sweetalert2";
import { getTerms } from "./actions/termActions";

// Main component
class SiteWrapper extends React.Component {
  constructor(props) {
    super(props);

    // Define the intial state
    this.state = {
      agentAddModal: false,
      announcementModal: false,
      dataExchangeModal: false,
      settingsModal: false,
      settingsFormData: {},
      settings: {},
      editMode: true,
      agentSelected: "hidden",
      errors: {},
    };

    // Bind the functions
    this.onLogoutClick = this.onLogoutClick.bind(this);
    this.toggleAdd = this.toggleAdd.bind(this);
    this.toggleAnnouncements = this.toggleAnnouncements.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
    this.toggleDataExchange = this.toggleDataExchange.bind(this);

    // Profile settings options
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.onChange = this.onChange.bind(this);

    // Announcement Options
    this.sendAnnouncement = this.sendAnnouncement.bind(this);
    this.onAnnouncementChange = this.onAnnouncementChange.bind(this);

    // Setting options
    this.settingsUpdate = this.settingsUpdate.bind(this);
    this.onSettingsChange = this.onSettingsChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    // Set the form Data
    this.setState({ settingsFormData: nextProps.auth.currentUser });
    this.setState({ settings: nextProps.settings });
    this.setState({ terms: nextProps.terms });
  }

  async componentWillMount() {
    // Check if the user is logged In
    if (this.props.auth.isAuthenticated == false) {
      window.location.replace("/login");
    }
    // Get the user profile
    this.props.userProfile();
    this.props.announcementGet();
    await this.props.settingGet();
    this.props.getTerms();
  }

  // Function to log the user out
  onLogoutClick(e) {
    e.preventDefault();
    // Log the user out
    this.props.logoutUser();
    // Redirect user to the login page
    window.location.replace("/login");
  }

  // ***  Setting functions start here ***

  // Handle the form submission
  handleFormSubmission = async e => {
    e.preventDefault();

    // Set the application data
    const settingsFormData = this.state.settingsFormData;

    // Send the application to action
    await this.props.registerUser(settingsFormData, "SELF");

    // Check for the errors
    if (isEmpty(this.state.errors)) {
      // Hide the form
      this.closeModals();
    }
  };

  // Function to make announcement
  async sendAnnouncement(e) {
    e.preventDefault();

    // Send the request to the backend server
    await this.props.announcementSet(this.state.announcement);

    // Check for the errors
    if (isEmpty(this.state.errors.announcement)) {
      this.closeModals();
      this.setState({ announcement: {} });
      Swal.fire(
        "Announcement Posted!",
        "All the agents will receieve message shortly.",
        "success"
      );
    }
  }

  // Function to handle settings update
  async settingsUpdate(e) {
    e.preventDefault();

    // Send request too the server
    await this.props.settingSet(this.state.settings);
    console.log(this.state.errors);

    // Check for the errors
    if (isEmpty(this.state.errors)) {
      this.closeModals();
      Swal.fire(
        "Settings updated!",
        "Your new settings are live on portal.",
        "success"
      );
    }
  }

  // Hanlde the input changes
  onChange(e) {
    this.setState({
      settingsFormData: {
        ...this.state.settingsFormData,
        [e.target.name]: e.target.value,
      },
    });
    if (e.target.name == "permission" && e.target.value == "1") {
      this.setState({ agentSelected: false });
    } else if (e.target.name == "permission" && e.target.value == "2") {
      this.setState({ agentSelected: "hidden" });
    }
  }

  // Function to add announment message change
  onAnnouncementChange(e) {
    this.setState({
      ...this.state,
      announcement: e.target.value,
    });
  }

  // Function to handle settings
  onSettingsChange(e) {
    this.setState({
      ...this.state,
      settings: {
        ...this.state.settings,
        [e.target.name]: e.target.value,
      },
    });
  }

  // Open the model to edit settings
  toggleAdd() {
    this.setState({
      agentAddModal: !this.state.agentAddModal,
    });
  }

  // Function to toggle Announcements model
  toggleAnnouncements() {
    this.setState({
      announcementModal: !this.state.announcementModal,
    });
  }

  // Function to toggle settings model
  toggleSettings() {
    this.setState({
      settingsModal: !this.state.settingsModal,
    });
  }

  // Function to toggle Data Exchange model
  toggleDataExchange() {
    this.setState({
      dataExchangeModal: !this.state.dataExchangeModal,
    });
  }

  // Close all the moddals
  closeModals() {
    this.setState({
      errors: {},
      agentAddModal: false,
      announcementModal: false,
      settingsModal: false,
    });
  }

  // ***  Setting functions end here   ***

  render() {
    // Get the authenticated and user object
    const { user } = this.props.auth;

    // Navigation links goes here for admins
    const navBarItemsAdmins = [
      {
        value: "Applications",
        to: "/applications",
        icon: "clipboard",
        LinkComponent: withRouter(NavLink),
      },
      {
        value: "Programs",
        icon: "book-open",
        subItems: [
          {
            value: "Programs",
            to: "/Programs",
            LinkComponent: withRouter(NavLink),
          },
          { value: "Program Availibity", to: "/availability" },
          { value: "Intakes", to: "/terms" },
        ],
      },
      {
        value: "Documents",
        to: "/documents",
        icon: "paperclip",
        LinkComponent: withRouter(NavLink),
      },
      {
        value: "Settings",
        icon: "settings",
        onClick: this.toggleSettings,
      },
      {
        value: "Others",
        icon: "arrow-down",
        subItems: [
          {
            value: "Admin Settings",
            to: "/admin",
            linkComponent: withRouter(NavLink),
          },
        ],
      },
    ];

    // Navigation links goes here for admission
    const navBarItemsAdmissions = [
      {
        value: "Applications",
        to: "/applications",
        icon: "clipboard",
        LinkComponent: withRouter(NavLink),
      },
      {
        value: "Programs",
        to: "/Programs",
        icon: "book-open",
        LinkComponent: withRouter(NavLink),
      },
      {
        value: "Documents",
        to: "/documents",
        icon: "paperclip",
        LinkComponent: withRouter(NavLink),
      },
    ];
    const navBarItemsAgents = [
      {
        value: "Applications",
        to: "/applications",
        icon: "clipboard",
        LinkComponent: withRouter(NavLink),
      },
      {
        value: "Programs",
        icon: "book-open",
        subItems: [
          {
            value: "Programs",
            to: "/Programs",
            LinkComponent: withRouter(NavLink),
          },
          { value: "Program Availibity", to: "/availability" },
        ],
      },
      {
        value: "Documents",
        to: "/documents",
        icon: "paperclip",
        LinkComponent: withRouter(NavLink),
      },
    ];

    let navBarItems;
    if (user.permission === 0) {
      navBarItems = navBarItemsAdmins;
      user.title = "Admin";
    } else if (user.permission === 2) {
      navBarItems = navBarItemsAdmissions;
      user.title = "Admissions";
    } else {
      navBarItems = navBarItemsAgents;
      user.title = "User";
    }

    // Setting links goes here
    const accountDropdownProps = {
      avatarURL: avatarGenerator(user.name),
      name: user.name,
      className: "customDropdown",
      description: user.title,
      options: [
        { icon: "settings", value: "Settings", onClick: this.toggleAdd },
        { icon: "log-out", value: "Sign out", onClick: this.onLogoutClick },
      ],
    };

    return (
      <Site.Wrapper
        headerProps={{
          href: "/",
          imageURL: "/assets/logo.png",
          navItems: (
            <Nav.Item type="div" className="d-none d-md-flex">
              {/* Button doesn't work while not accepting new applications */}
              {this.state.settings.acceptNewApps === false &&
                this.props.auth.user.permission === 1 && (
                  <Button
                    size="sm"
                    outline
                    color="primary"
                    RootComponent="a"
                    onClick={() => {
                      Swal.fire({
                        title: "Currently not accepting new applications",
                        text: "Please try again later",
                      });
                    }}
                  >
                    New Application
                  </Button>
                )}
              {/* Staff can still create applications even if not accepting new applications */}
              {this.state.settings.acceptNewApps === false &&
                (this.props.auth.user.permission === 0 ||
                  this.props.auth.user.permission === 2) && (
                  <Button
                    href="/form"
                    size="sm"
                    outline
                    color="primary"
                    RootComponent="a"
                  >
                    New Application
                  </Button>
                )}

              {/* Button works for everyone except applying agents */}
              {this.state.settings.acceptNewApps === true &&
              this.props.auth.user.agentAppStatus === 50
                ? ""
                : this.state.settings.acceptNewApps === true &&
                  this.props.auth.user.permission !== 5 && (
                    <Button
                      href="/form"
                      size="sm"
                      outline
                      color="primary"
                      RootComponent="a"
                    >
                      New Application
                    </Button>
                  )}
            </Nav.Item>
          ),
          accountDropdown: accountDropdownProps,
        }}
        navProps={{ itemsObjects: navBarItems }}
        footerProps={{
          copyright: <React.Fragment>Copyright © 2024</React.Fragment>,
        }}
      >
        {this.props.children}
        <AgentAdd
          formData={this.state.settingsFormData}
          onChange={this.onChange}
          toggleAdd={this.toggleAdd}
          agentAddModal={this.state.agentAddModal}
          handleFormSubmission={this.handleFormSubmission}
          editMode={this.state.editMode}
          errors={this.state.errors}
          agentSelected={this.state.agentSelected}
          globalForm={true}
        />
        <Announcements
          onChange={this.onAnnouncementChange}
          toggle={this.toggleAnnouncements}
          errors={this.state.errors}
          announcementModal={this.state.announcementModal}
          onClick={this.sendAnnouncement}
        />
        <PortalSettings
          onChange={this.onSettingsChange}
          toggle={this.toggleSettings}
          settings={this.state.settings}
          errors={this.state.errors}
          announcementModal={this.state.settingsModal}
          terms={this.state.terms}
          onClick={this.settingsUpdate}
        />
        <DataExchange
          onChange={this.onSettingsChange}
          toggle={this.toggleDataExchange}
          settings={this.state.settings}
          errors={this.state.errors}
          dataExchangeModal={this.state.dataExchangeModal}
          terms={this.state.terms}
          onClick={this.settingsUpdate}
        />
      </Site.Wrapper>
    );
  }
}

SiteWrapper.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  setCurrentApplication: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  announcement: state.announcement.latest,
  settings: state.settings,
  terms: state.terms,
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  {
    logoutUser,
    setCurrentApplication,
    announcementSet,
    announcementGet,
    registerUser,
    userProfile,
    settingSet,
    settingGet,
    getTerms,
  }
)(withRouter(SiteWrapper));
