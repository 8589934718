import React, { useState, useEffect } from "react";
import {
  Button,
  Center,
  Container,
  Loader,
  Paper,
  TextInput,
  ThemeIcon,
  Title,
  Text,
  Flex,
} from "@mantine/core";
import {
  IconCircleCheckFilled,
  IconCircleXFilled,
  IconMailShare,
} from "@tabler/icons-react";
import { useVerifyApplicant } from "../hooks/useRegister";
import useSearchParams from "../hooks/useSearchParams";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const VERIFY_STATE_UI = {
  loading: {
    title: "Verifiying",
    body: "We are verifying your email...",
    icon: <Loader color="blue" />,
  },
  noToken: {
    title: "Invalid verify link",
    body: "Please make sure to register an account before verifying.",
    icon: (
      <ThemeIcon color="red.6" size="xl">
        <IconCircleXFilled />
      </ThemeIcon>
    ),
  },
  expiredToken: {
    title: "Expired Token",
    body: "Token had expired. Please click Resend to get a new token",
    icon: (
      <ThemeIcon color="red.6" size="xl">
        <IconCircleXFilled />
      </ThemeIcon>
    ),
  },
  doubleVerifying: {
    title: "Already Verified",
    body: "You had previously verified. Please proceed to login.",
    icon: (
      <ThemeIcon color="red.6" size="xl">
        <IconCircleXFilled />
      </ThemeIcon>
    ),
  },
  resend: {
    title: "Email Sent",
    body: "Resent Verification Link. Plesae check email. ",
    icon: (
      <ThemeIcon size="xl">
        <IconMailShare />
      </ThemeIcon>
    ),
  },
  success: {
    title: "Successfully Verified!",
    body: "You are allow to login with register email and password.",
    icon: (
      <ThemeIcon color="green.6" size="xl">
        <IconCircleCheckFilled />
      </ThemeIcon>
    ),
  },
};

const Verify = props => {
  const history = useHistory();
  const { getParams } = useSearchParams();
  const urlParams = getParams();
  const token = urlParams.get("token");
  const { isLoading, isError, error } = useVerifyApplicant(token);

  if (isLoading) {
    return (
      <Center h="100vh">
        <Paper shadow="xs" px="md" py="xl" maw={"400px"} miw="400px">
          <VerifyState element={VERIFY_STATE_UI.loading} />
        </Paper>
      </Center>
    );
  }

  if (!token) {
    return (
      <Center h="100vh">
        <Paper shadow="xs" px="md" py="xl" maw={"400px"} miw="400px">
          <VerifyState element={VERIFY_STATE_UI.noToken} />
        </Paper>
      </Center>
    );
  }

  if (isError && error.response.data.message === "expired") {
    return (
      <Center h="100vh">
        <Paper shadow="xs" px="md" py="xl" maw={"400px"} miw="400px">
          <VerifyState
            element={VERIFY_STATE_UI.expiredToken}
            btnText={"Resend"}
            clickHanlder={() => history.push("/resend")}
          />
        </Paper>
      </Center>
    );
  }

  if (isError && error.response.data.message === "verified") {
    return (
      <Center h="100vh">
        <Paper shadow="xs" px="md" py="xl" maw={"400px"} miw="400px">
          <VerifyState
            element={VERIFY_STATE_UI.doubleVerifying}
            btnText={"Login"}
            clickHanlder={() => history.push("/login")}
          />
        </Paper>
      </Center>
    );
  }

  return (
    <Center h="100vh">
      <Paper shadow="xs" px="md" py="xl" maw={"400px"} miw="400px">
        <VerifyState
          element={VERIFY_STATE_UI.success}
          btnText={"Login"}
          clickHanlder={() => history.push("/login")}
        />
      </Paper>
    </Center>
  );
};

export default Verify;

const VerifyState = ({ element, btnText, clickHanlder }) => {
  return (
    <Flex
      gap="md"
      justify="center"
      align="center"
      direction="column"
      wrap="wrap"
    >
      <Container>{element.icon}</Container>
      <Title order={3}>{element.title}</Title>
      <Text align="center">{element.body}</Text>
      {btnText && <Button onClick={() => clickHanlder()}>{btnText}</Button>}
    </Flex>
  );
};
