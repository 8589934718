import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Divider,
  Grid,
  Paper,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { countryList } from "../../utils/countriesList";
import { useGetOpenPrograms } from "./api";
import { useGetAllTerms } from "../Applications/api";
import { useAppStore } from "../../lib/zustandStore";

// TODO: this needs to be changed
// there is no institution list, everything should be under tbc
// for the sake of future proofing, we will create everything under tbc institution
// in case we offer other institutions in the future
export const institutionList = [
  { value: "Toronto Business College", label: "Toronto Business College" },
];

const Step1 = ({ form }) => {
  const [institution, setInstitution] = useState("Toronto Business College");
  const [term, setTerm] = useState("");
  const [termList, setTermList] = useState([{ label: "", value: "" }]);
  const [programList, setProgramList] = useState([{ label: "", value: "" }]);

  const isEdit = useAppStore(state => state.isEdit);

  const { data: termData } = useGetAllTerms();

  /** @type {{data: import('../../types').OpenProgram[], isLoading: boolean}} */
  const {
    data: programData,
    isLoading: programIsLoading,
  } = useGetOpenPrograms();

  // set initial values for state based on the application pulled from server
  useEffect(
    () => {
      // only set if it has not been set
      // this stops useEffect from making the state "stuck"
      if (!institution) {
        setInstitution(
          form?.values?.personal?.ProgramsAvailable?.Program?.institution
        );
      }
      if (!term) {
        setTerm(form?.values?.personal?.ProgramsAvailable?.term);
      }
    },
    [form]
  );

  // create list of programs based on selected campus/institution and term
  useEffect(
    () => {
      if (programData?.length > 0) {
        let temp = programData
          .filter(
            e => e.Program?.institution === institution && e.term === term
          )
          .map(e => ({
            label: `${e.Program?.name} - ${e.Term?.name}`,
            value: e.id,
          }));
        setProgramList(temp);
      }
    },
    [institution, programData, term]
  );

  useEffect(
    () => {
      if (termData) {
        setTermList(termData.map(e => ({ label: e.name, value: e.id })));
      }
    },
    [termData]
  );

  const handleInstitutionChange = (e = "", v = "") => {
    if (v === "") {
      setInstitution(e);
    }
    if (e === "") {
      setTerm(v);
    }

    form.setValues(values => ({
      ...values,
      personal: {
        ...values.personal,
        program: "",
        program_secondary: "",
      },
    }));
  };

  if (programIsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Stack>
        <Paper shadow="xs" px="md" py="xl">
          <Title size="h3">Applicant Profile Information</Title>
          <Divider my="lg" />
          <Grid columns={24}>
            <Grid.Col span={24}>
              <TextInput
                required
                label="Mailing Address"
                {...form.getInputProps("personal.MailingAddress")}
              />
            </Grid.Col>
            <Grid.Col span={24}>
              <TextInput
                label="Mailing Address (Line 2)"
                {...form.getInputProps("personal.MailingAddress2")}
              />
            </Grid.Col>
            <Grid.Col span={8}>
              <TextInput
                required
                label="City"
                {...form.getInputProps("personal.city")}
              />
            </Grid.Col>
            <Grid.Col span={8}>
              <TextInput
                required
                label="Province/State"
                {...form.getInputProps("personal.province")}
              />
            </Grid.Col>
            <Grid.Col span={8}>
              <TextInput
                required
                label="Postal Code"
                {...form.getInputProps("personal.postalCode")}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Select
                required
                searchable
                label="Country"
                data={countryList}
                {...form.getInputProps("personal.country")}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                required
                label="Telephone"
                {...form.getInputProps("personal.telephone")}
              />
            </Grid.Col>
            {isEdit ? (
              <>
                <Grid.Col span={12}>
                  <Select
                    required
                    disabled
                    label="Term"
                    nothingFound="No open terms found"
                    name="term"
                    value={term}
                    data={termList}
                    onChange={v => handleInstitutionChange("", v)}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextInput
                    required
                    disabled
                    label="Primary Program"
                    searchable
                    {...form.getInputProps(
                      "personal.ProgramsAvailable.Program.code"
                    )}
                    nothingFound="No programs found"
                  />
                </Grid.Col>
              </>
            ) : (
              <>
                <Grid.Col span={12}>
                  <Select
                    required
                    label="Term"
                    nothingFound="No open terms found"
                    name="term"
                    value={term}
                    data={termList}
                    onChange={v => handleInstitutionChange("", v)}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Select
                    required
                    label="Primary Program"
                    searchable
                    data={programList}
                    {...form.getInputProps("personal.program")}
                    nothingFound="No programs found"
                  />
                </Grid.Col>
                {/* TODO: change this logic in future so it isn't hardcoded */}
                {form?.values?.personal?.program == 756 ? (
                  <Grid.Col span={24}>
                    <Text>
                      Please note that while this French language course aims to
                      enhance your language skills, it does not guarantee
                      specific career outcomes, job placements, or immigration
                      points for permanent residency applications in Canada.
                    </Text>
                    <Checkbox
                      {...form.getInputProps("personal.frenchDisclaimer")}
                      label={
                        <>
                          By clicking this button, you acknowledge that: Toronto
                          Business College does not guarantee that this course
                          will affect your career or immigration outcome, and
                          results and performance may differ based on your
                          language skills, effort, and other external factors.
                          <span style={{ color: "#fa5252" }}> *</span>
                        </>
                      }
                    />
                  </Grid.Col>
                ) : null}
              </>
            )}
          </Grid>
        </Paper>

        {/* TODO: this needs to be made conditional. Only form ECA, PSW, Business. (programs) */}
        <Paper shadow="xs" px="md" py="xl">
          <Title size="h3">Emergency Contact (optional)</Title>
          <Divider my="lg" />
          <Grid>
            <Grid.Col span={12}>
              <TextInput
                {...form.getInputProps("personal.name_emergency")}
                label="Name"
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label="Phone number"
                {...form.getInputProps("personal.phone_emergency")}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                {...form.getInputProps("personal.email_emergency")}
                label="Email address"
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                {...form.getInputProps("personal.relation_emergency")}
                label="Your relationship"
                placeholder="Father"
              />
            </Grid.Col>
          </Grid>
        </Paper>
      </Stack>
    </>
  );
};

export default Step1;
