import {
  Button,
  Center,
  Image,
  Paper,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import React from "react";

import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import axios from "axios";
import { IconCheck } from "@tabler/icons-react";
import config from "../config/config";

const initialValues = {
  email: "",
};

const ForgotPassword = () => {
  const form = useForm({
    initialValues,
  });

  const handleSubmit = async values => {
    try {
      const pwResp = await axios.post(
        `${config.server}/users/forgot-password`,
        {
          email: values.email,
        }
      );
      if (pwResp.data.success) {
        notifications.show({
          title: "Your password has been reset, please check your email.",
          icon: <IconCheck />,
          color: "teal",
        });
      }
      form.setValues({ email: "" });
    } catch (error) {
      form.setErrors(error.response.data);
    }
  };

  return (
    <Center h="100vh">
      <Paper shadow="xs" px="md" py="xl" maw={"400px"} miw="400px">
        <Center>
          <Stack>
            <Center>
              <Image src="/assets/logo.png" className="mainLogo" maw="300px" />
            </Center>
            <form onSubmit={form.onSubmit(values => handleSubmit(values))}>
              <Center>
                <Title>Forgot Password</Title>
              </Center>
              <Text my="lg">
                Enter the email associated with your account and we'll send you
                a new password
              </Text>
              <TextInput
                label="Email Address"
                {...form.getInputProps("email")}
              />
              <Button mt={"md"} type="submit">
                Reset Password
              </Button>
            </form>
          </Stack>
        </Center>
      </Paper>
    </Center>
  );
};

export default ForgotPassword;
