import { Badge, Button, ScrollArea, Skeleton, Table } from "@mantine/core";
import React from "react";
import dayjs from "dayjs";
import { applicationStatus } from "../../utils/applicationStatus";
import { tableHeaders } from "./Applications2";
import { useHistory } from "react-router-dom";
import queryClient from "../../lib/queryClient";

let userTableHeaders = [
  "Assigned ID",
  "Status",
  "Term",
  "Program/Micro-credential",
  "Created",
  "",
];

const ApplicationsTable = ({ appData, isLoading }) => {
  const history = useHistory();
  const myInfo = queryClient.getQueryData("myInfo");

  /** @param {number} id */
  const handleView = id => {
    history.push(`/application/view/${id}`);
  };

  const tableBodyData = () => {
    return appData?.map(e => (
      <tr key={e.id}>
        <td>EDUS01{e.id}</td>
        {(myInfo?.permission === 0 || myInfo?.permission === 2) && (
          <td>{e.User.name}</td>
        )}
        <td>
          <Badge color={applicationStatus(e.status).color}>
            {applicationStatus(e.status).statusText}
          </Badge>
        </td>
        <td>{e.ProgramsAvailable.Term?.name}</td>
        <td>{e.ProgramsAvailable.Program.code}</td>
        <td>{dayjs(e.createdAt).format("MMM DD YYYY")}</td>
        <td>
          <Button variant="outline" onClick={() => handleView(e.id)}>
            View
          </Button>
        </td>
      </tr>
    ));
  };

  return (
    <ScrollArea mb={8}>
      <Table verticalSpacing="xs" withBorder striped bg="white">
        {" "}
        <thead>
          <tr>
            {isLoading ? (
              <Skeleton height={40} />
            ) : myInfo?.permission === 1 ? (
              userTableHeaders.map(e => <th key={e}>{e}</th>)
            ) : (
              tableHeaders.map(e => <th key={e}>{e}</th>)
            )}
          </tr>
        </thead>
        <tbody>{tableBodyData()}</tbody>
      </Table>
    </ScrollArea>
  );
};

export default ApplicationsTable;
