import { useHistory, useLocation } from "react-router-dom";

/**
 * @returns {{getParams: function, setParams: function,  updateParam: function}}
 */
const useSearchParams = () => {
  const history = useHistory();
  const { search, pathname } = useLocation();

  const getParams = () => {
    return new URLSearchParams(search);
  };

  /**
   * @param {URLSearchParams} newParams
   */
  const setParams = newParams => {
    history.push({
      pathname,
      search: newParams.toString(),
    });
  };

  /**
   * @param {string} key
   * @param {string} value
   */
  const updateParam = (key, value) => {
    const params = getParams();

    if (value.trim() === "") {
      params.delete(key);
    } else {
      params.set(key, value);
    }

    setParams(params);
  };

  return { getParams, setParams, updateParam };
};

export default useSearchParams;
