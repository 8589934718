export const DocumentType = {
  PASSPORT: 1,
  ID_CARD: 2,
  ACADEMIC_DOC: 3,
  IELTS_PTE_OTHERS: 4,
  OTHER_DOC: 5,
  LOA: 6,
  APPLICATION_FORM: 6,
  CONDITIONAL_OFFER_LETTER: 7,
  WIRE_TRANSFER_RECEIPT: 8,
  ALL_MERGED_IN_ONE: 9,
};

// 12, 13, 14 used in api/documents.js for something?
// application form 10 }],
export const AppDocTypes = {
  "1": "Passport",
  "2": "ID card",
  "3": "Academic Documents",
  "4": "IELTS/PTE/OTHERS",
  "5": "Others Documents",
  "6": "Letter of acceptance",
  "7": "Conditional offer letter",
  "8": "Wire transfer receipt",
  "9": "All merged in one",
  "10": "Highschool marksheets",
  "11": "Highschool certificate",
  "15": "Bachelors marksheets",
  "16": "Bachelors certificate",
  "17": "Post-Graduate marksheets",
  "18": "Post-Graduate certificate",
  "19": "Employment Documents",
  "20": "Status in Canada Documents - if applicable",
};

export const permissionTypes = {
  0: "Admin",
  1: "User",
  2: "Admissions",
};
