import {
  Center,
  ThemeIcon,
  Title,
  Stack,
  Text,
  Paper,
  Loader,
} from "@mantine/core";
import {
  IconCircleCheckFilled,
  IconCircleXFilled,
  IconExclamationCircle,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import useSearchParams from "../../hooks/useSearchParams";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";

const MESSAGE_ICON = {
  loading: <Loader />,
  success: (
    <ThemeIcon size="xl" color="green">
      <IconCircleCheckFilled />
    </ThemeIcon>
  ),
  processing: <Loader />,
  failed: (
    <ThemeIcon size="xl" color="red">
      <IconCircleXFilled />
    </ThemeIcon>
  ),
  requireAction: (
    <ThemeIcon size="xl" color="yellow">
      <IconExclamationCircle />
    </ThemeIcon>
  ),
  others: (
    <ThemeIcon size="xl" color="red">
      <IconCircleXFilled />
    </ThemeIcon>
  ),
};

const Completion = () => {
  const { getParams } = useSearchParams();
  const history = useHistory();
  const searchParams = getParams();
  const redirectStatus = searchParams.get("redirect_status");
  const paymentIntentClientSecret = searchParams.get(
    "payment_intent_client_secret"
  );

  const stripe = useStripe();
  const [message, setMessage] = useState({
    icon: "loading",
    title: "",
    body: "",
  });

  useEffect(() => {
    if (!redirectStatus && !paymentIntentClientSecret) {
      return history.push("/payment");
    }
  }, []);

  useEffect(
    () => {
      if (!stripe) {
        return;
      }

      if (!paymentIntentClientSecret) {
        return;
      }

      stripe
        .retrievePaymentIntent(paymentIntentClientSecret)
        .then(({ paymentIntent }) => {
          switch (paymentIntent.status) {
            case "succeeded":
              setMessage({
                ...message,
                icon: "success",
                title: "Payment succeeded!",
                body:
                  "Your payment process is successful. You may return to your homepage.",
              });
              break;
            case "processing":
              setMessage({
                ...message,
                icon: "processing",
                title: "Payment Processing",
                body:
                  "We are processing your payment. You may return to your homepage while it is processing.",
              });
              break;
            case "requires_payment_method":
              setMessage({
                ...message,
                icon: "failed",
                title: "Payment failed",
                body: "Your payment was not successful, please try again.",
              });
              break;
            case "requires_action":
              setMessage({
                ...message,
                icon: "requireAction",
                title: "Action Required",
                body:
                  "We had made two microdeposit to your bank account, it may take up to 1-2 days to shown on your bank account. Please check your email to verify the amount of both microdeposit. Once it is verified, your application status will change to Paid. \n You are have 3 verify attemps within 10 days. ",
              });
              break;
            default:
              setMessage({
                ...message,
                icon: "others",
                title: "Something went wrong.",
                body: "Please contact IT support about this error.",
              });
              break;
          }
        });
    },
    [stripe]
  );

  return (
    <Center>
      <Paper p={24} maw={500} mah={500}>
        <Stack>
          <Center>{MESSAGE_ICON[message.icon]}</Center>
          <Title>{message.title}</Title>
          <Text>{message.body}</Text>
        </Stack>
      </Paper>
    </Center>
  );
};

export default Completion;
