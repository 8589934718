import * as React from "react";

import { Route, Switch } from "react-router-dom";

import AppView from "../ApplicationForm/AppView";

function ApplicationRoute() {
  return (
    <Switch>
      <Route path="/application/view/:id" component={AppView} />
    </Switch>
  );
}

export default ApplicationRoute;
