import React, { useState } from "react";
import SiteWrapper from "../../SiteWrapper.react";
import ProgramForm from "./CreateForm";
import { useDisclosure } from "@mantine/hooks";
import ProgramTable from "./ProgramTable";

const Index = () => {
  const [addEdit, setAddEdit] = useState("add");
  const [opened, { open, close }] = useDisclosure(false);

  const [modalData, setModalData] = useState({
    code: "",
    name: "",
    url: "",
    institution: "",
    id: "",
  });

  /**
   * @typedef {"edit" | "add"} modalType
   * @param {import("./programTypes").ProgramData} program
   * @param {modalType} type
   */
  const openProgramForm = (program, type) => {
    switch (type) {
      case "edit": {
        setModalData({
          code: program.code,
          name: program.name,
          url: program.url,
          id: program.id,
          institution: program.institution,
        });
        setAddEdit("edit");
        open();
        break;
      }
      case "add": {
        setModalData({
          code: "",
          name: "",
          url: "",
          id: 0,
          institution: "",
        });
        setAddEdit("add");
        open();
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <ProgramForm
        opened={opened}
        close={close}
        addEdit={addEdit}
        data={modalData}
      />
      <ProgramTable openProgramForm={openProgramForm} />
    </>
  );
};

export default Index;
